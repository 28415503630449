import { library } from '@fortawesome/fontawesome-svg-core';
import { faBell } from '@fortawesome/free-regular-svg-icons/faBell';
import { faBuilding as faRegularBuilding } from '@fortawesome/free-regular-svg-icons/faBuilding';
import { faCircleCheck as faCircleCheckRegular } from '@fortawesome/free-regular-svg-icons/faCircleCheck';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons/faCircleQuestion';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons/faLightbulb';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons/faThumbsUp';
import { faClipboardQuestion } from '@fortawesome/free-solid-svg-icons/faClipboardQuestion';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowDownShortWide } from '@fortawesome/free-solid-svg-icons/faArrowDownShortWide';
import { faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons/faArrowDownWideShort';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons/faArrowRightFromBracket';
import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons/faArrowsUpDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBellConcierge } from '@fortawesome/free-solid-svg-icons/faBellConcierge';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBox } from '@fortawesome/free-solid-svg-icons/faBox';
import { faBrain } from '@fortawesome/free-solid-svg-icons/faBrain';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faBroom } from '@fortawesome/free-solid-svg-icons/faBroom';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons/faCalendarCheck';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons/faCalendarPlus';
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons/faCartShopping';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons/faChartColumn';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons/faChartSimple';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons/faCheckDouble';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faCircleNodes } from '@fortawesome/free-solid-svg-icons/faCircleNodes';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons/faCirclePlay';
import { faCircleStop } from '@fortawesome/free-solid-svg-icons/faCircleStop';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons/faClockRotateLeft';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faCouch } from '@fortawesome/free-solid-svg-icons/faCouch';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import { faDroplet } from '@fortawesome/free-solid-svg-icons/faDroplet';
import { faEarthEurope } from '@fortawesome/free-solid-svg-icons/faEarthEurope';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFan } from '@fortawesome/free-solid-svg-icons/faFan';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faFileExport } from '@fortawesome/free-solid-svg-icons/faFileExport';
import { faFileLines } from '@fortawesome/free-solid-svg-icons/faFileLines';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons/faFileSignature';
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faGears } from '@fortawesome/free-solid-svg-icons/faGears';
import { faGrip } from '@fortawesome/free-solid-svg-icons/faGrip';
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake';
import { faHourglassEnd } from '@fortawesome/free-solid-svg-icons/faHourglassEnd';
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse';
import { faIcons } from '@fortawesome/free-solid-svg-icons/faIcons';
import { faInbox } from '@fortawesome/free-solid-svg-icons/faInbox';
import { faIndustry } from '@fortawesome/free-solid-svg-icons/faIndustry';
import { faInfinity } from '@fortawesome/free-solid-svg-icons/faInfinity';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons/faLaptopCode';
import { faLeaf } from '@fortawesome/free-solid-svg-icons/faLeaf';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlassChart';
import { faMasksTheater } from '@fortawesome/free-solid-svg-icons/faMasksTheater';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons/faMicrochip';
import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons/faMobileScreenButton';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons/faNetworkWired';
import { faPeopleRoof } from '@fortawesome/free-solid-svg-icons/faPeopleRoof';
import { faPerson } from '@fortawesome/free-solid-svg-icons/faPerson';
import { faPersonChalkboard } from '@fortawesome/free-solid-svg-icons/faPersonChalkboard';
import { faPersonCircleExclamation } from '@fortawesome/free-solid-svg-icons/faPersonCircleExclamation';
import { faPersonRunning } from '@fortawesome/free-solid-svg-icons/faPersonRunning';
import { faPersonShelter } from '@fortawesome/free-solid-svg-icons/faPersonShelter';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faPieChart } from '@fortawesome/free-solid-svg-icons/faPieChart';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons/faPuzzlePiece';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faRecycle } from '@fortawesome/free-solid-svg-icons/faRecycle';
import { faRestroom } from '@fortawesome/free-solid-svg-icons/faRestroom';
import { faRightLeft } from '@fortawesome/free-solid-svg-icons/faRightLeft';
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons/faScrewdriver';
import { faSitemap } from '@fortawesome/free-solid-svg-icons/faSitemap';
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons/faSnowflake';
import { faSoap } from '@fortawesome/free-solid-svg-icons/faSoap';
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons/faSolarPanel';
import { faSpa } from '@fortawesome/free-solid-svg-icons/faSpa';
import { faSquareParking } from '@fortawesome/free-solid-svg-icons/faSquareParking';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons/faStethoscope';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';
import { faTemperatureHalf } from '@fortawesome/free-solid-svg-icons/faTemperatureHalf';
import { faTicket } from '@fortawesome/free-solid-svg-icons/faTicket';
import { faTreeCity } from '@fortawesome/free-solid-svg-icons/faTreeCity';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons/faUserDoctor';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons/faUserGraduate';
import { faUserLock } from '@fortawesome/free-solid-svg-icons/faUserLock';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUsersViewfinder } from '@fortawesome/free-solid-svg-icons/faUsersViewfinder';
import { faUtensils } from '@fortawesome/free-solid-svg-icons/faUtensils';
import { faVolumeLow } from '@fortawesome/free-solid-svg-icons/faVolumeLow';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons/faWandMagicSparkles';
import { faWarning } from '@fortawesome/free-solid-svg-icons/faWarning';
import { faWater } from '@fortawesome/free-solid-svg-icons/faWater';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays';
import { faChartArea } from '@fortawesome/free-solid-svg-icons/faChartArea';
import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons/faCloudSunRain';
import { faWind } from '@fortawesome/free-solid-svg-icons/faWind';
import { faUmbrella } from '@fortawesome/free-solid-svg-icons/faUmbrella';

library.add(
  faBuilding,
  faLightbulb,
  faRegularBuilding,
  faBook,
  faSitemap,
  faRobot,
  faIcons,
  faCogs,
  faPuzzlePiece,
  faChartLine,
  faMagnifyingGlassChart,
  faPeopleRoof,
  faPieChart,
  faTicket,
  faClipboardCheck,
  faNetworkWired,
  faFile,
  faExclamationTriangle,
  faKey,
  faUser,
  faUserLock,
  faClipboard,
  faInbox,
  faCalendarCheck,
  faFileSignature,
  faCar,
  faCreditCard,
  faTable,
  faPhone,
  faEnvelope,
  faStopwatch,
  faWarning,
  faMobileScreenButton,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faFire,
  faMicrochip,
  faDoorOpen,
  faBolt,
  faFan,
  faGears,
  faRecycle,
  faSnowflake,
  faTemperatureHalf,
  faVolumeLow,
  faWater,
  faCloud,
  faHouse,
  faUserCircle,
  faUsers,
  faClock,
  faThumbsUp,
  faStethoscope,
  faPersonCircleExclamation,
  faDatabase,
  faSolarPanel,
  faIndustry,
  faQuestion,
  faGrip,
  faFileLines,
  faCheck,
  faDroplet,
  faPlug,
  faLeaf,
  faCircleNodes,
  faExclamation,
  faLock,
  faPersonRunning,
  faCalendar,
  faCircleCheck,
  faEllipsisVertical,
  faLink,
  faTachometerAlt,
  faCircleExclamation,
  faChartColumn,
  faLaptopCode,
  faCalendarPlus,
  faCirclePlay,
  faCircleCheckRegular,
  faSliders,
  faRightLeft,
  faFileExport,
  faHourglassEnd,
  faInfinity,
  faCircleStop,
  faCircle,
  faCircleQuestion,
  faTags,
  faWandMagicSparkles,
  faScrewdriver,
  faEye,
  faHandshake,
  faBrain,
  faBriefcase,
  faPerson,
  faChartSimple,
  faStar,
  faArrowsUpDown,
  faArrowDown,
  faArrowUp,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faCalendar,
  faPlus,
  faUsersViewfinder,
  faCouch,
  faBroom,
  faPersonChalkboard,
  faUserGraduate,
  faMasksTheater,
  faUtensils,
  faPeopleRoof,
  faSpa,
  faSoap,
  faUserDoctor,
  faTreeCity,
  faSquareParking,
  faBellConcierge,
  faPersonShelter,
  faRestroom,
  faCartShopping,
  faBox,
  faClockRotateLeft,
  faCheckDouble,
  faGear,
  faEarthEurope,
  faUserCheck,
  faArrowRightFromBracket,
  faBars,
  faXmark,
  faBell,
  faWrench,
  faClipboardQuestion,
  faCalendarDays,
  faChartArea,
  faCloudSunRain,
  faWind,
  faUmbrella,
);
